/****
   Font Families
 ****/
@font-face {
    font-family: 'Arial Rounded Bold';
    src: url('../fonts/arlrdbd.woff');
}
/****
   Hide Helpers
 ****/
@media only screen and (min-width:471px){
    .hide-on-desktop{
	display:none!important;
    }
}
@media only screen and (max-width:470px){
    .hide-on-mobile{
	display:none!important;
    }
}
/****
   General
 ****/
/**headings**/
h1, h2, h3, h4, h5, h6 {
    font-weight:700;
    font-family: 'Arial Rounded Bold';
}
h1 {
    font-size: 45px;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 25px;
}
h4 {
    font-size: 20px;
}
strong {
    font-weight: 700;
}

/*make list items have circles*/
section li {
    list-style: circle!important;
    list-style-position: inside!important;
}

/**brand colors**/
.daisy-green-text{
    color: #92c83f!important;
}
.daisy-green-background{
    background-color: #92c83f!important;
}
.daisy-grey-text{
    color: #58585b!important;
}
.daisy-grey-background{
    background-color: #58585b!important;
}
.daisy-green-gradient-text-1 {
    /*background: -webkit-linear-gradient(left, #92c83f 0%, #92c83f 35%, #074f18 75%, #074f18 100%)!important;*/
    /*background: -webkit-linear-gradient(left, #92c83f 0%, #92c83f 20%, #074f18 45%, #074f18 100%)!important;*/
    background: -webkit-linear-gradient(left, #92c83f 0%, #074f18 100%)!important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
    padding: 0!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    line-height: 70px;
    width: fit-content;
}
@media only screen and (max-width:470px){
    .daisy-green-gradient-text-1 {
	line-height: unset;
    }
}
/*fix center align issue after making width fit-content*/
.center {
    margin: auto;
}

.daisy-green-gradient-background-1 {
    background: -webkit-linear-gradient(left, #92c83f 50%, #074f18 100%)!important;
}

.daisy-green-gradient-border-1 {
    border: double 2px transparent!important;
    border-radius: 80px!important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #92c83f,#074f18)!important;
    background-origin: border-box!important;
    background-clip: content-box, border-box!important;
    height: 48px!important;
    padding:unset!important;
}
.daisy-green-gradient-border-1-inner-wrapper{
    padding-top:10px;
}
/**force plain green text for sections with grey backgrounds**/
/*
   .daisy-grey-background h1, .daisy-grey-background h2 {
   color: #92c83f!important;
   background: unset!important;
   -webkit-background-clip: unset!important;
   -webkit-text-fill-color: unset!important;
   }
 */
.daisy-grey-background h1:not(.white-text), .daisy-grey-background h2:not(.white-text) {
    color: #92c83f!important;
    background: unset!important;
    -webkit-background-clip: unset!important;
    -webkit-text-fill-color: unset!important;
}

/**section containers**/
/*remove margin from h2 and give the h2 some padding*/
.container h2 {
    margin-top: 0px;
    padding-top: 30px;
}

/**section curves**/
/*white curve*/
/*bottom*/
.section-curve-bottom-white .scb-wrapper {
    background-color:#58585b;
}
.section-curve-bottom-white .scb-curve {
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    padding-top: 100px;
    background: #fff;
}
/*top*/
.section-curve-top-white .sct-wrapper {
    background-color:#58585b;
}
.section-curve-top-white .sct-curve {
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    padding-bottom: 100px;
    background: #fff;
}

/*grey curve*/
/*bottom*/
.section-curve-bottom-grey .scb-wrapper {
    background-color:#fff;
}
.section-curve-bottom-grey .scb-curve {
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    padding-top: 100px;
    background: #58585b;
}
/*top*/
.section-curve-top-grey .sct-wrapper {
    background-color:#fff;
}
.section-curve-top-grey .sct-curve {
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    padding-bottom: 100px;
    background: #58585b;
}


/**page title sections**/
section.page-title-section {
    text-align: center;
}

/**buttons**/
.daisy-btn-std {
    padding: 8px 15px;
    text-align: center;
    border-radius: 20px;
    color: #fff;
    border:unset;
    cursor:pointer;
    max-height:45px;
}
*[class*="daisy-btn-std"][class*="daisy-green-gradient-background-1"] {
    /* background: -webkit-linear-gradient(left, #92c83f 50%, #074f18 100%)!important; */
    background: url(../img/img/gradient-button2.png)!important;
    background-size: 120%!important;
    background-position: center center!important;
}

/**input style override (get rid of material design styles)**/
input {
    background: white;
    border-radius: 20px!important;
    max-height: 35px;
    border: 1px solid #000!important;
    padding-left: 10px!important;
    margin-left: -10px!important;
}

/**override materialize SELECT styles**/
select {
    display: initial;
    margin: 10px 0;
    border: 1px solid #000!important;
    border-radius: 20px!important;
}

/**Slick Slider Global Styles**/
.left-right-triangle-wrapper {
    cursor: pointer;
    z-index:99;
}
/*HIDE arrows on mobile devices*/
@media only screen and (max-width:600px){
    .left-right-triangle-wrapper {
	display:none!important;
    }
}

/**slick dots**/
.slick-dots li.slick-active {
    transform: scale(4);
    transition: transform 0.4s;
}
.slick-dots li.slick-active button:before{
    color:#92c83f!important;
}

/*trangle wrappers*/
.right-triangle-wrapper {
    float: right;
}
.left-triangle-wrapper {
    float: left;
}

/*grey triangles left/right*/
.triangle-left-grey {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-right: 50px solid #58585b;
    border-bottom: 25px solid transparent;
}
.triangle-right-grey {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 50px solid #58585b;
    border-bottom: 25px solid transparent;
}
/*green triangles left/right*/
.triangle-left-green {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-right: 50px solid #92c83f;
    border-bottom: 25px solid transparent;
}
.triangle-right-green {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 50px solid #92c83f;
    border-bottom: 25px solid transparent;
}


/**Form Fields**/
.daisy-form-input {
    border-bottom: unset!important;
    background: white;
    border-radius: 20px!important;
    max-height: 35px;
    border: 1px solid #58585b!important;
    padding-left: 10px!important;
    width: 100%;
    min-height: 35px;
    color: #58585b!important;
}
.daisy-form-input::placeholder{
    color: #58585b!important;
}
.daisy-form-select {
    display: block;
    border-radius: 50px;
    min-width: calc(100% + 10px);
    margin-bottom: 8px;
    border: 1px solid #58585b!important;
    color: #58585b!important;
    margin-left: -10px;
}

/****
   Full Width Header Image Sections
 ****/
#full-width-header-image-wrapper {
    margin-top: -20px;
    margin-bottom: 20px;
}
#full-width-header-image-wrapper img {
    aspect-ratio: 1920/500;
    width: 100%;
    height: auto;
}

/****
   Pagination
 ****/
.daisy-paginator-style-1 {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}
.daisy-paginator-style-1 .pagination {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    justify-content: center;
}
.daisy-paginator-style-1 * {
    color: black;
}
.daisy-paginator-style-1 span.page_disabled.prev {
    display: none;
}

/****
   HEADER
 ****/
/****main nav****/
nav {
    height: 100px;
    background: white;
}
.brand-logo {
    margin-top: 15px;
}
#nav-desktop {
    margin-top: 15px;
}
#nav-desktop a {
    color: black;
}

/****
   Main Navigation Bar
 ****/
/*fixed nav*/
.navbar-fixed {
    height: 120px; /*use this to adjust offset*/
}

/**animate icons on hover**/
a.dmn-item-link:hover img {
    transform: scale(1.3);
    transition: transform 0.4s;
}

/*nav elem*/
.main-navigation > div,
#desktop-nav {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: row;
    /*justify-content: flex-start;*/
    flex-wrap: nowrap;
    height: 100px;
    justify-content: space-between;
}

/*force single line for all level 3 menu items*/
.sub-menu-level-3 .dmn-item-wrapper a {
    white-space: nowrap;
}

/*Add indicators to top level items with drop down menu*/
.dmn-item-wrapper.has-dropdown.dropdown-level-1 > a::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    margin-left: 5px;
    display: block;
    float: right;
    margin-top: 30px;
}
.dropdown-level-1 > a:hover::after {
    border-top-color: #92c83f!important;
}

@media only screen and (min-width:1146px) and (max-width:1350px){
    .main-navigation > div,
    #desktop-nav {
	gap:15px;
    }
    .main-navigation a{
	font-size:12px;
    }

    .dmn-item-wrapper.has-dropdown.dropdown-level-1 > a::after {
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-top: 3px solid #000;
	margin-left: 3px;
    }
}
@media only screen and (min-width:993px) and (max-width:1145px){
    .main-navigation > div,
    #desktop-nav {
	gap:5px;
    }
    .main-navigation a{
	font-size:10px;
    }
    .dmn-item-wrapper.has-dropdown.dropdown-level-1 > a::after {
	border-left: 2px solid transparent;
	border-right: 2px solid transparent;
	border-top: 2px solid #000;
	margin-left: 2px;
    }
}

/*logo*/
.nav-logo {
    display: flex;
}
@media only screen and (min-width:471px){
    /*add margin on large screens*/
    .nav-logo {
	margin-right: 30px;
    }
}
@media only screen and (max-width:470px){
    .nav-logo{
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
    }
}

/*all a tags in nav*/
.main-navigation a:not(.nav-logo) {
    text-decoration: none;
    color: #000;
    max-height: 35px;
}
@media only screen and (max-width: 1366px) and (min-width: 470px){
    a.dmn-item-link {
	font-size: 13px;
    }    
}

.main-navigation a:hover {
    text-decoration: none;
    color: #92c83f;
}

/*all sub menu wrappers*/
.sub-menu {
    position: absolute;
    display:none;
    min-width: 355px;
    min-height: 350px;
    padding: 25px 45px 25px 25px!important;
    padding-inline-start: 0;
    /*left: 9999px;*/
    border-radius: 30px;
    z-index:9;
}

/*make entire menu the same grey background*/
.sub-menu {
    background-color: #444!important;
}

/*sub menu item labels (text portion)*/
.sub-menu p {
    color: #fff;
    height: 48px;
    margin: 0;
    line-height: 48px;
}

.sub-menu p:hover, .has-dropdown:hover > a > p {
    color: #92c83f;
}

/*sub menu icons (image portion - left of text)*/
.dmn-item-link img:nth-of-type(1) {
    max-width: 35px;
}

/*sub menu arrow (chevron)*/
.sub-menu-arrow {
    display: flex;
}
.sub-menu-arrow i {
    color: #fff;
    font-size: 16px;
    height: auto;
}
.sub-menu-arrow:hover i {
    color:#92c83f;
}

/*sub menu level 1 wrapper*/
.sub-menu-level-1 {
    background-color: #222;
    /*margin-left: -60px;*/ /*use for left aligned nav bar*/
    margin-left: -245px; /*use for right aligned nav bar*/
}

/*triangle above submenu level 1*/
.sub-menu.sub-menu-level-1::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    top: -5px;
    /*left: 70px;*/ /*use for left aligned nav bar*/
    left: 270px; /*use for right aligned nav bar*/
    position: absolute;
}

/*menu item icons*/
.dmn-item-link img {
    max-width: 25px!important;
}

/*sub menu level 1 item wrapper*/
.sub-menu-level-1 .dmn-item-wrapper a {
    display: grid;
    grid-template-columns: 25px 1fr 50px;
    align-items: center;
    grid-column-gap: 10px;
}

/*hover level 1*/
.dropdown-level-1:hover .sub-menu-level-1{
    display:flex;
    flex-direction: column;
}


/*sub menu level 2 wrapper*/
.sub-menu-level-2 {
    background-color: #444;
    margin-left: 250px;
    top: 0;
    border-radius: 0px 20px 20px 0;
}

/*sub menu level 2 item wrapper*/
.sub-menu-level-2 .dmn-item-wrapper a{
    display: grid;
    grid-template-columns: 25px 1fr 50px;
}

/*hover level 2*/
.dropdown-level-2:hover .sub-menu-level-2{
    display:flex;
    flex-direction: column;
}


/*sub menu level 3 wrapper*/
.sub-menu-level-3 {
    background-color: #222;
    margin-left: 250px;
    top: 0;
    border-radius: 0px 20px 20px 0;
}

/*sub menu level 3 item wrapper*/
.sub-menu-level-3 .dmn-item-wrapper a{
    display: grid;
    grid-template-columns: 25px 1fr 50px;
}

/*hover level 3*/
.dropdown-level-3:hover .sub-menu-level-3{
    display: flex;
    flex-direction: column;
}

/**Contact btn (main desktop nav)**/
.contact-us-main-nav {
    max-height: 40px;
    line-height: 20px;
    width: 150px;
}
.contact-us-main-nav a {
    color:#fff!important;
}

/****side nav****/
a.sidenav-trigger {
    color: black;
    margin-top: 25px;
    align-self: flex-start;
    position: absolute!important;
    right: 15px;
}
/*adjust sidenav width*/
.sidenav{
    width:370px;
}
/*indent each dropdown menu list to help sperate the child items from the parents*/
ul[id^="mm-"][id$="-dropdown"] {
    margin-left: 15px;
}

/****
   FOOTER
 ****/
/***Footer Top Shape***/
/***Footer Top***/
#footer-top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.footer-menu-heading {
    font-size: 20px;
}
#footer-top a {
    color: white;
}
/*mobile*/
@media only screen and (max-width:470px){
    #footer-top {
	grid-template-columns: 1fr;
    }
    .footer-menu-heading {
	text-align:center;
	font-size: 20px;
	font-weight: 900;
    }
    .footer-menu-items {
	text-align: center;
	font-size: 16px;
    }

    /*tap target fix*/
    .footer-menu-item {
	padding: 12px;
    }
}

/**contact us items with icons**/
.footer-items-with-icons {
    display: grid;
    grid-template-columns: 45px 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
}
.footer-item-image {
    max-width: 30px;
}
/*grid for items directly inside a tags*/
#footer-menu-contact-us a {
    display: grid;
    grid-template-columns: auto 1fr;
}

/***Footer Middle***/
#footer-middle {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    padding-bottom: 30px;
}
/*mobile*/
@media only screen and (max-width:470px){
    #footer-middle {
	grid-template-columns: 100%;
    }
}

#footer-newsletter-form {
    display: grid;
    grid-template-columns: auto 1fr 200px;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
}
/*mobile*/
@media only screen and (max-width:470px){
    #footer-newsletter-form {
	grid-template-columns: 1fr;
    }
}

#footer-email-subscribe {
    border: unset;
    margin-bottom: 6px;
}
#footer-email-input {
    border-bottom: unset!important;
    background: white;
    border-radius: 20px!important;
    max-height: 35px;
    border: unset!important;
}
#footer-email-input::placeholder {
    padding-left: 20px!important;
}
#footer-newsletter-form label.active {
    font-size: 16px;
    color: #fff;
    margin-right:20px;
}

#footer-social-grid {
    display: grid;
    grid-template-columns: 30px 30px 30px 30px 30px;
    grid-gap: 10px;
    justify-content: end;
}
img.footer-social-img {
    max-width: 30px;
}
/*mobile*/
@media only screen and (max-width:470px){
    #footer-social-grid {
	justify-content: center;
    }
    .footer-items-with-icons {
	grid-template-columns: 100%;
    }
    .footer-items-with-icons img {
	display: none;
    }
    #footer-newsletter-form label.active {
	text-align:center;
    }
}


/*****
 ** SPECIAL OFFERS PAGE
 *****/
/**top banner**/
section#specials-top-banner {
    margin-top: -20px;
}
#stb-wrapper img {
    aspect-ratio: 1920/500;
    max-width:100%;
}

/**intro**/
#specials-intro-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px;
    margin-bottom: 60px;
}
div#specials-intro-image {
    display: none;
}
@media only screen and (max-width:1460px){
    #specials-intro-b-grid {
	display: grid;
	grid-gap: 20px;
    }
}
@media only screen and (max-width:470px){
    #specials-intro-image img {
	max-width: 100%;
    }
}

/**specials list**/
.special-offer-section {
    display: grid;
    grid-template-columns:1fr 1fr;
    margin-bottom: 30px;
}

.special-offer-section:nth-of-type(odd) .sos-img,
.special-offer-section:nth-of-type(odd) .sos-info-wrapper,
.special-offer-section:nth-of-type(even) .sos-img,
.special-offer-section:nth-of-type(even) .sos-info-wrapper{
    grid-row:1/2;
}

.special-offer-section:nth-of-type(odd) .sos-img {
    grid-column: 2/3;
}
.special-offer-section:nth-of-type(odd) .sos-info-wrapper {
    grid-column: 1/2;
}

.special-offer-section:nth-of-type(even) .sos-img {
    grid-column: 1/2;
}
.special-offer-section:nth-of-type(even) .sos-info-wrapper {
    grid-column: 2/3;
}
.sos-img img {
    max-width: 100%;
}
.sos-sub-title h3 {
    font-size: 20px;
    margin-top: -15px;
}
.sos-body {
    margin-bottom: 20px;
}

.sos-price-and-button-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.sos-price-grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: 10px;
    align-items: center;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    padding: 10px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.sos-price-and-button-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    grid-column-gap: 20px;
}
span.sos-price-side-text {
    color: #fff;
}
span.sos-price {
    color: #fff;
    font-size: 35px;
    font-weight:900;
}

/**before & after price**/
.sos-price::before, .sos-price::after {
    display: block;
    font-size: 12px;
}
/*before price*/
.sos-price::before {
    content: "Rental prices starting from ";
    margin-bottom: -8px;
}
/*after price*/
.sos-price::after {
    content: "Excluding VAT";
    margin-top: -8px;
}



/*Grid on mobile*/
@media only screen and (max-width:470px){
    .special-offer-section, .sos-price-and-button-wrapper, #specials-intro-grid {
	grid-template-columns: 100%!important;
    }
    .special-offer-section * {
	grid-column: unset!important;
	grid-row: unset!important;
    }
    .special-offer-section .sos-title {
	height: unset!important;
    }
    .sos-btn {
	margin-top: 35px;
	text-align:unset!important;
    }

    div#specials-intro-b-grid {
	display: grid;
	grid-row-gap: 20px;
    }
    #specials-intro-btn-1, #specials-intro-btn-2{
	background-size: cover!important;
    }
}


/****
   Form Thank You Page
 ****/
.form-thank-you{
    
}


/****
   HOME PAGE
 ****/
/***Animations***/
/**flip text in cta**/
/*Vertical Sliding*/
/************************/
.slidingVertical{
    display: inline;
    text-indent: 8px;
}
.slidingVertical span{
    animation: topToBottom 16s linear infinite 0s;
    -ms-animation: topToBottom 16s linear infinite 0s;
    -webkit-animation: topToBottom 16s linear infinite 0s;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}
.slidingVertical span:nth-child(2){
    animation-delay: 2s;
    -ms-animation-delay: 2s;
    -webkit-animation-delay: 2s;
}
.slidingVertical span:nth-child(3){
    animation-delay: 4s;
    -ms-animation-delay: 4s;
    -webkit-animation-delay: 4s;
}
.slidingVertical span:nth-child(4){
    animation-delay: 6s;
    -ms-animation-delay: 6s;
    -webkit-animation-delay: 6s;
}
.slidingVertical span:nth-child(5){
    animation-delay: 8s;
    -ms-animation-delay: 8s;
    -webkit-animation-delay: 8s;
}
.slidingVertical span:nth-child(6){
    animation-delay: 10s;
    -ms-animation-delay: 10s;
    -webkit-animation-delay: 10s;
}
.slidingVertical span:nth-child(7){
    animation-delay: 12s;
    -ms-animation-delay: 12s;
    -webkit-animation-delay: 12s;
}
.slidingVertical span:nth-child(8){
    animation-delay: 14s;
    -ms-animation-delay: 14s;
    -webkit-animation-delay: 14s;
}

/*topToBottom Animation*/
@-moz-keyframes topToBottom{
    0% { opacity: 0; }
    5% { opacity: 0; -moz-transform: translateY(-50px); }
    10% { opacity: 1; -moz-transform: translateY(0px); }
    25% { opacity: 0; -moz-transform: translateY(0px); }
    30% { opacity: 0; -moz-transform: translateY(50px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@-webkit-keyframes topToBottom{
    0% { opacity: 0; }
    5% { opacity: 0; -webkit-transform: translateY(-50px); }
    10% { opacity: 1; -webkit-transform: translateY(0px); }
    25% { opacity: 0; -webkit-transform: translateY(0px); }
    30% { opacity: 0; -webkit-transform: translateY(50px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@-ms-keyframes topToBottom{
    0% { opacity: 0; }
    5% { opacity: 0; -ms-transform: translateY(-50px); }
    10% { opacity: 1; -ms-transform: translateY(0px); }
    25% { opacity: 0; -ms-transform: translateY(0px); }
    30% { opacity: 0; -ms-transform: translateY(50px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
/****************/

/***CTA Top***/
#ctath-grid {
    display: grid;
    /*grid-template-columns: 1fr 1fr;*/
    grid-template-columns: 40% 1fr;
    grid-gap: 30px;
}
div#ctath-left {
    position: relative;
    z-index: 10;
}

/*animated text font*/
@media only screen and (min-width:470px){
    #home-page #ctath-left h2 {
	font-size: 60px;
    }
}

#ctathl-btn-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
@media only screen and (max-width:470px){
    #ctath-grid, #ctathl-btn-grid{
	grid-template-columns: 100%;
    }

    #cta-top-home h2 {
	/*text-align: center;*/
    }
}
/*shift video col up on desktop to fix white space issue*/
@media only screen and (min-width:471px){
    #ctath-right {
	margin-top: -115px;
	margin-bottom: -45px;

	position: relative;
	overflow-x: hidden;
    }

    /*make video larger*/
    #ctath-right video {
	width: 1000px;
	height: auto;
	
	z-index: 1;
	position: relative;
	margin-left:-130px;
	border: 0px solid!important;
    }
}

/*cover random line below video*/
#tcta-vid-mask {
    display: block;
    width: 100%;
    position: relative;
    height: 30px;
    background: white;
    margin-top: -15px;
}
@media only screen and (max-width:470px){
    #tcta-vid-mask {
	margin-top: -32px;
    }
}

/*make title overlap video when screens are smaller*/
#cta-top-home h1 {
    position: relative;
    z-index: 10;
}

/***Five column gresy strip***/
#five-column-grey-strip .container {
    z-index: 10;
    position: relative;
}
#fcgs-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.fcgsg-title {
    font-size: 30px;
    margin-bottom: -20px;
    color: #fff;
    font-family: 'Arial Rounded Bold';
}
.fcgsg-text {
    color: #fff;
    font-size: 20px;
}
@media only screen and (max-width:470px)
{
    #fcgs-grid {
	grid-template-columns: 100%;
	
    }
}

/***Filters***/
#home-filters {
    margin-top: 30px;
}
#hf-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    text-align: center;
}
@media only screen and (max-width:470px){
    #hf-grid {
	grid-template-columns: 100%;
    }
}
.home-filter-item {
    color: #92c83f;
    cursor: pointer;
    font-size: 18px;
    line-height: 20px;
}
.home-filter-item span {
    color:#000;
}

/***Featured Solutions***/
#fs-grid {
    display: grid;
    grid-gap: 60px;
}
.featured-solution {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-image: url(../img/img/cow_print_gradient.jpg);
    background-position-y: bottom;
    background-repeat: no-repeat;
    align-items: end;
}
@media only screen and (max-width:470px){
    .featured-solution {
	grid-template-columns: 100%;
    }

    .fs-text{
	grid-row:1/2;
    }
    .featured-solution .fs-img{
	grid-row:2/3;
    }
    .featured-solution p{
	grid-row:3/4;
    }
    .featured-solution a{
	grid-row:4/5;
    }
    
}
.fs-img {
    max-height: 600px;
    overflow: hidden;
    text-align: center;
}

/**styles for intersection observer events**/
/*image scale*/
.fs-img img {
    transition: transform 3s;
    transform: scale(0.3);
}
div[class*="featured-solution"][class*="observed"] img {
    transform: scale(1);
}
/*text opacity*/
.fs-text {
    transition: opacity 3s;
    opacity: 0;
}
div[class*="featured-solution"][class*="observed"] .fs-text {
    opacity: 1;
}

/***About us (home) section***/
#about-home {
    margin-top: 80px;
    transition: opacity 3s;
    opacity: 0;
}
section[id="about-home"][class*="observed"] {
    opacity: 1!important;
}

.ah-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
@media only screen and (max-width:470px){
    .ah-grid {
	grid-template-columns: 100%;
    }
}
@media only screen and (min-width:471px){
    #about-home-text-bottom {
	grid-column: 1/3;
    }
}
#ah-video-wrapper {
    border-radius: 40px;
    overflow: hidden;
    min-height: 300px;
}
.ah-grid iframe {
    height: 100%;
    width: 100%;
}

/***Our solutions (home)***/
#home-our-solutions h2{
    transition: opacity 3s;
    opacity: 0;
}
section[id="home-our-solutions"][class*="observed"] h2{
    opacity: 1!important;
}


.osh-item-wrapper {
    text-align: center;
}
.osh-img-wrapper {
    background: url(https://gcz.co.za/projects/Daisy/inc/img/img/circle_gradient_stroke.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: calc(100% - 40px);
}
.osh-img-wrapper:hover {
    background: url('https://gcz.co.za/projects/Daisy/inc/img/img/circle_gradient.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: calc(100% - 40px);
}


@media only screen and (min-width:800px){
    /*make every odd item a bit lower*/
    #hosc-items-wrapper .osh-item-wrapper:nth-of-type(odd) {
	margin-top: 50px;
    }
}

.osh-img-wrapper img {
    max-width: 180px;
}
@media only screen and (max-width:470px){
    .osh-img-wrapper img {
	max-width: 100%;
    }
    .osh-img-wrapper {
	margin-left: 20px;
    }
    .osh-item-wrapper p{
	margin-left:0!important;
    }
}

.osh-item-wrapper p {
    color: black;
    margin-left: -40px;
}

#hos-carousel {
    position: relative;
}
#hos-carousel .left-triangle-wrapper {
    position: absolute;
    left: -80px;
    top: 35%;
}
#hos-carousel .right-triangle-wrapper {
    position: absolute;
    right: -80px;
    top: 35%;
}
/*
#hos-carousel .left-triangle-wrapper {
    margin-top: 85px;
    margin-left:-40px;
}
#hos-carousel .right-triangle-wrapper {
    margin-top: -200px;
    margin-right: -40px;
}
*/

/****
   "Observed" Animation
   -- 'observed' class is added by scrollTrigger() js function
 ****/
/*flip right*/
@keyframes animate-flip-right {
    0% {
	transform: rotatey(-90deg);
    }
    100%{
	transform: rotatey(0deg);
    }
}
/*
   div[class*='animate-flip-right'][class*='observed'] {
   animation-name: animate-flip-right;
   animation-duration: 1s;
   animation-delay: 2s;
   animation-iteration-count: 1;
   animation-timing-function: ease;
   animation-fill-mode: forwards;
   }
 */

/***Why Daisy***/
#why-daisy {
    padding: 60px 0;
}
#why-daisy h2:not(#about-us-page h2) {
    transition: opacity 3s;
    opacity: 0;
}
section[id="why-daisy"][class*="observed"] h2 {
    opacity: 1!important;
}
.wd-item-wrapper {
    text-align: center;
}
.wd-quotes {
    margin-bottom: -90px;
    font-size: 40px;
    line-height: 40px;
}
.wd-text {
    border-radius: 30px;
    box-shadow: 2px 2px 8px 0px #000;
}
.wd-text p {
    font-size: 12px;
    padding: 30px 30px 70px 30px; 
}
.wd-name p {
    font-size: 10px;
    color: #fff;
}
.wd-company p {
    font-size: 8px;
    color: #fff;
}
.wd-image {
    margin-top: -70px;
}
.wd-image img {
    text-align: center;
    margin: auto;
}

.wd-item-wrapper:nth-of-type(even) .wd-text {
    background: #fff;
    color: #000;
}

.wd-item-wrapper:nth-of-type(odd) .wd-text {
    background: -webkit-linear-gradient(left, #92c83f 50%, #074f18 100%)!important;
    color:#fff;
}

/*current item styles*/
#wdc-items-wrapper .slick-current {
    transform: scale(1);
    transition: transform 1s;
}
#wdc-items-wrapper .slick-current .wd-quotes {
    padding-top: 30px;
}

/*non current item styles*/
.wd-item-wrapper:not(.slick-current) {
    transition: transform 1s;
    transform: scale(0.5);
}

/***Locate A Branch***/
#locate-branch-wrapper {
    background-position: center center!important;
    background-size: 600px;
    background-repeat: no-repeat!important;
    height: 640px;
    text-align: center;
}
@media only screen and (min-width:471px){
    #locate-branch-wrapper {
	background-size: 600px!important;
    }
}
#lb-text {
    margin-top: 135px;
}
#lb-img {
    padding-top: 95px;
    margin-bottom: -135px;
    /*padding-left: 52px;*/
}


#lb-img img {
    transition: transform 1s;
    transform: scale(0.5);
}
/*intersection observer*/
/*
   div[id="lb-img"][class*="observed"] img {
   transform: scale(1)!important;
   }
 */
@keyframes animation-grow {
    0% {
	transform: scale(0.5);
    }
    100%{
	transform: scale(1);
    }
}
div[id="lb-img"][class*='observed'] img[class*='animation-grow'] {
    animation-name: animation-grow;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}


/**partners and suppliers**/
#partners-and-suppliers h2:not(#about-us-page h2) {
    transition: opacity 3s;
    opacity: 0;
}
section[id="partners-and-suppliers"][class*="observed"] h2{
    opacity: 1!important;
}

.partner-supplier-wrapper img {
    max-width: 100%;
}

/*container*/
.pas-carousel-wrapper {
    position: relative;
}
/*left arrow*/
#pas-left {
    position: absolute;
    left: -80px;
    top: 38%;
}
/*right arrow*/
#pas-right {
    position: absolute;
    right: -80px;
    top: 38%;
}
/*
@media only screen and (max-width:1700px) and (min-width:1350px){
    #pas-left {
	margin-top: 80px!important;
    }
    #pas-right {
	margin-top: -130px!important;
    }
}

@media only screen and (max-width:1349px) and (min-width:1035px) {
    #pas-left {
	margin-top: 45px!important;
    }
    #pas-right {
	margin-top: -100px!important;
    }
}
*/


/*current item styles*/
#pas-c-items-wrapper .slick-current {
    transform: scale(1);
    transition: transform 1s;
}

/*non current item styles*/
.partner-supplier-wrapper:not(.slick-current) {
    transition: transform 1s;
    transform: scale(0.4);
}


/**The bullpen article carousel**/
#bull-pen h2{
    transition:opacity 3s;
    opacity: 0;
}
section[id="bull-pen"][class*="observed"] h2{
    opacity: 1!important;
}

#bull-pen {
    padding-bottom: 60px;
}
/*container*/
.bp-carousel-wrapper {
    position: relative;
}
/*left arrow*/
#bp-left{
    position:absolute;
    left:-80px;
    top:30%
}
/*right arrow*/
#bp-right{
    position:absolute;
    right:-80px;
    top:30%
}

/*other*/
.bp-img-wrapper {
    text-align: center;
    margin-bottom: -60px;
}
.bp-img-wrapper img {
    border-radius: 50%;
    width: 150px;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    text-align: center;
    margin: auto;
    border: 2px solid #fff;
}

.bp-text-wrapper {
    text-align: center;
    background: #eee;
    padding: 25px;
    border-radius: 32px;
}

.bpt-title {
    margin-top: 60px;
    font-weight:700;
    font-size:20px;
}


/*current item*/
#bp-c-items-wrapper .slick-current {
    transition: transform 1s;
    transform: scale(1);
}

.bullpen-item-wrapper:not(.slick-current) {
    transition: transform 1s;
    transform: scale(0.7);
}


/****
   About Us Page
 ****/
/**Our History**/
#our-history {
    margin-top: 60px;
}
.oh-item-wrapper{
    display: grid;
    grid-template-columns: 50px 1fr;
}
.ohc-item-date {
    grid-row: 1/3;
    grid-column: 1/2;
    transform: rotate(90deg);
    height: 40px;
    margin-top: 41px;
    background: #fff;
    width: 54px;
    margin-left: 21px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.ohc-item-title {
    font-weight: 900;
    grid-row: 1/2;
    grid-column: 2/3;
    background: #fff;
    margin-bottom: -11px;
    margin-left: 20px;
    padding-left: 10px;
    z-index: 9;
}
.ohc-item-text {
    grid-row: 2/3;
    grid-column: 2/3;
    padding: 20px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
}

#oh-carousel{
    position:relative;
}
#ohc-right {
    position:absolute;
    top:50%;
    right:-80px;
}
#ohc-left {
    position:absolute;
    top:50%;
    left:-80px;
}

.ai-text {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}
#ai-video-wrapper{
    border-radius:50px;
    overflow:hidden;
}
#ai-video-wrapper iframe {
    width: 100%;
    height: 500px;
}


/****
   Services Page
 ****/
/**Intro**/
#service-intro {
    margin-bottom: 60px;
}
#si-grid {
    display: grid;
    grid-template-columns: 1fr 300px;
}
@media only screen and (max-width:470px){
    #si-grid {
	grid-template-columns: 100%;
    }
    #si-g-image {
	grid-row: 1/2;
    }
    #si-g-image img {
	max-width: 100%;
    }
}

/**Solutions carousel**/
#spsc-left {
    margin-left: -40px;
    margin-top: 110px;
}
#spsc-right {
    margin-right: -40px;
    margin-top: -157px;
}
/*make every odd item a bit lower*/
@media only screen and (min-width:800px){
    #spsc-items-wrapper .osh-item-wrapper:nth-of-type(odd) {
	margin-top: 50px;
    }
}
#si-g-text {
    display: flex;
    /*align-items: flex-end;*/
    margin-bottom: 30px;
    flex-direction: column;
}

/**More About Our Services**/
#mas-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding-bottom: 60px;
}
@media only screen and (max-width:470px){
    #mas-grid, #mas-grid * {
	grid-template-columns: 100%;
	grid-column: 1/2;
    }
}

/**Service Solutions**/
.spss-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 60px;
}
.spss-i-title {
    align-self: end;
    margin-bottom: -15px;
}
.spss-i-image {
    grid-row: 1/4;
    max-height: 400px;
    overflow: hidden;
}
.spss-i-text {
    grid-row: 2/3;
}
.spss-i-btn {
    max-height: 40px;
    cursor: pointer;
    max-width: 165px;
}

.spss-grid:nth-of-type(odd) .spss-i-title {

}
.spss-grid:nth-of-type(odd) .spss-i-text {
    grid-column: 1/2;
}
.spss-grid:nth-of-type(odd) .spss-i-image {
    grid-column: 2/3;
    text-align: center;
    margin: auto;
}
.spss-grid:nth-of-type(odd) .spss-i-btn {
    grid-row: 3/4;
    grid-column: 1/2;
}

.spss-grid:nth-of-type(even) .spss-i-title {

}
.spss-grid:nth-of-type(even) .spss-i-text {
    grid-column: 2/3;
}
.spss-grid:nth-of-type(even) .spss-i-image {
    grid-column: 1/2;
    text-align: center;
    margin: auto;
}
.spss-grid:nth-of-type(even) .spss-i-btn {

}
@media only screen and (max-width:470px){
    #spss-wrapper *{
	grid-template-columns: 100%;
	grid-column:1/2;
    }
    
    .spss-grid {
	
    }
    .spss-i-image {
	grid-row: 2/3;
    }
    .spss-i-image img {
	max-width: 100%;
    }
    .spss-i-title {
	grid-row: 1/2;
	text-align:center
    }
    .spss-i-text {
	grid-row: 3/4;
    }
    .spss-i-btn {
	grid-row: 4/5!important;
    }

}

/**service call stats**/
#sp-stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    text-align:center;
}
@media only screen and (max-width:470px){
    #sp-stats-grid {
	grid-template-columns: 100%;
    }
}
.sp-s-i {
    padding-top: 20px;
}
.sp-s-i-title {
    font-size: 22px;
}
.sp-s-i-value {
    font-size: 40px;
    margin: 0;
    padding-bottom: 30px;
}

/**solutions forms**/
.service-form-popup {
    display:none;
    z-index:999;
    position:fixed;
    top:10px;
    max-width: 600px;
    margin: auto;
    border-radius: 50px;
    box-shadow: 2px 2px 8px 2px;
    overflow: hidden;
    background: #fff;
    left: calc(50vw - (600px / 2));
}
.form-popup-header{
    margin-bottom:20px;
}
.form-popup-header h3 {
    margin: 0;
    padding: 20px;
    text-align: center;
    font-size: 30px;
}
.form-popup-header::after {
    content: "X";
    display: block;
    position: absolute;
    top: 26px;
    right: 30px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}
.fpu-form-wrapper {
    padding: 20px 30px 20px 20px;
}
/*error message (3rd level solution page form validation error)*/
div[id$="-service-call-form-popup"] #form-error-wrapper {
    margin-left: 25px;
}

.service-form-btn-wrapper {
    display: flex;
    justify-content: center;
    /*margin-bottom: 60px;*/
}
#std-service-call-form-section{
    padding-bottom:60px;
}
@media only screen and (max-width:470px){
    .service-form-popup {
	max-width: 320px;
	left: calc(50vw - (320px / 2));
	top:120px;
    }
    .form-popup-header h3 {
	max-width: 250px;
	margin: auto;
	font-size: 22px;
    }
}


/****
   Daisy Net
 ****/
/**intro**/
#dni-grid {
    display: grid;
    grid-template-columns: 1fr 300px;
}
#dni-g-text {
    align-self: end;
    margin-bottom: 30px;
}
#dni-g-btn {
    grid-row: 2/3;
    grid-column: 1/2;
}
#dni-g-image {
    grid-row: 1/3;
    grid-column: 2/3;
    overflow: hidden;
    max-height: 450px;
    margin-top:-60px;
}
#dni-g-image img {
    max-width: 100%;
}
@media only screen and (max-width:470px){
    #dni-grid{
	grid-template-columns: 100%;
    }
    #dni-grid *{
	grid-column: unset!important;
	grid-row: unset!important;
    }
    #dni-g-image {
	margin-top:0;
    }
    #dni-g-image, .dnsl-g-item-image {
	text-align: center;
    }
    #dnvs-wrapper {
	padding: 30px 0 30px 0!important;
    }
    #dnvs-video-wrapper iframe {
	height: 300px!important;
    }
}

/**Video Section**/
#dnvs-wrapper {
    padding: 60px;
}
#dnvs-video-wrapper {
    text-align: center;
    border-radius: 50px;
    overflow: hidden;
}
#dnvs-video-wrapper iframe {
    width: 100%;
    height:600px;
}

/**Filters**/
#dnf-strip {
    display: flex;
    gap: 30px;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom:60px;
    flex-wrap:wrap;
}
.dnf-item a {
    /*border: 1px solid #000;*/
    padding: 10px 20px;
    border-radius: 20px;
    color: #000;
    cursor:pointer;
}
@media only screen and (max-width:470px){
    #dnf-strip {
	flex-direction: column;
    }
}

/**Solutions List**/
.solution-list-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-bottom:60px;
}
.dnsl-g-item-image {
    grid-row: 1/4;    
    max-height: 300px;
    overflow: hidden;
}
.dnsl-g-item-image img {
    max-width:100%
}
.dnsl-g-item-text{
    grid-row: 2/3;
}
.dnsl-g-item-title {
    display: flex;
    align-items: flex-end;
}
.dnsl-g-item-button {
    grid-row: 3/4;
}


.solution-list-grid:nth-of-type(odd) .dnsl-g-item-image {
    grid-column: 1/2;
}
.solution-list-grid:nth-of-type(odd) .dnsl-g-item-text {
    grid-column: 2/3;
}
.solution-list-grid:nth-of-type(odd) .dnsl-g-item-button {
    grid-column: 2/3;
}

.solution-list-grid:nth-of-type(even) .dnsl-g-item-image {
    grid-column: 2/3;    
}
.solution-list-grid:nth-of-type(even) .dnsl-g-item-text {
    grid-column: 1/2;
}
.solution-list-grid:nth-of-type(even) .dnsl-g-item-button {
    grid-column: 1/2;
}

@media only screen and (max-width: 470px){
    .solution-list-grid{
	grid-template-columns: 100%;
    }
    .solution-list-grid *{
	grid-column:unset!important;
	grid-row:unset!important;
	margin-top:0!important;
    }
    .dnsl-g-item-image {
	grid-row: 2/3!important;
	margin-top: -90px;
    }
    .dnsl-g-item-title {
	text-align: center;
	margin: auto;
    }
    .dnsl-g-item-title h3 {
	text-align: center;
    }
    .dnsl-g-item-button {
	text-align: center;
    }
}

/**(ALL solutions pages) Partners and suppliers section**/
#partners-and-suppliers {
    padding: 60px 10px;
}
.solutions-page #partners-and-suppliers h2 {
    opacity: 1!important;
}

/**Get In Touch CTA ("Common" Element & Styles)**/
#get-in-touch-cta {
    padding-bottom: 60px;
}


/****
   The Bullpen (Article Page) [Page View]
 ****/
/*main grid and content*/
/*
div#tbpvp-grid {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 30px;
}
#tbpvp-g-title {
    grid-row: 1/2;
    grid-column: 1/3;
}
#tbpvp-g-date {
    grid-row: 2/3;
    grid-column: 1/3;
}
#tbpvp-g-featured-image {
    grid-row: 3/4;
    grid-column: 2/3;
}
*/
#tbpvp-g-featured-image img {
    max-width:100%
}
/*
#tbpvp-g-body-1 {
    grid-row: 4/5;
    grid-column: 2/3;
}
*/
#tbpvp-g-youtube {
    /*grid-row: 5/6;*/
    /*grid-column: 2/3;*/
    height: 500px;
    border-radius: 50px;
    overflow: hidden;
}
@media only screen and (max-width:470px){
    #tbpvp-g-youtube {
	height: 250px;
    }
}
/*
#tbpvp-g-body-2 {
    grid-row: 6/7;
    grid-column: 2/3;
}

#bullpen-search-box {
    grid-row: 3/7;
    grid-column: 1/2;
    margin-top:30px;
}
*/

@media only screen and (max-width:470px){

}
/*hidden search field for category*/
input#bpsb-categories-search-input {
    display: none;
}




/*blog page restructure*/
/*
#bpsb-keyword-search-form form, #bpsb-categories-search-form form {
    display: grid;
    grid-template-columns: 1fr 100px;
    grid-gap: 20px;
    align-items: center;
}
#the-bullpen-page-view #bullpen-search-box {
    grid-template-columns: 100%;
    align-items: start;
    max-height: 315px;
}
#bpsb-keyword-search-form > p, #bpsb-categories-search-form > p {
    font-size: 20px;
    font-weight: 900;
}
@media only screen and (max-width:470px){
    #bullpen-search-box,
    #bpsb-keyword-search-form form,
    #bpsb-categories-search-form form {
	grid-template-columns: 100%!important;
    }
}
*/
/*mobile*/
/*
@media only screen and (max-width:470px){
    #bpsb-social-grid {
	justify-content: center;
    }
    .bpsb-items-with-icons {
	grid-template-columns: 100%;
    }
    .bpsb-items-with-icons img {
	display: none;
    }
    #bpsb-newsletter-form label.active {
	text-align:center;
    }
}
*/
/**New styles july 2023**/
div#tbpvp-g-featured-image {
    max-width: 650px;
    text-align: center;
    margin: auto;
    margin-bottom: 60px;
}

/**related articles**/
#related-articles {
    padding-bottom: 60px;
}
#ra-title {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
@media only screen and (max-width:470px){
    #ra-title {
	grid-template-columns:100%;
    }
    #ra-title-line{
	display:none;
    }
}
.ra-i-title h3 {
    font-size: 20px;
}
#ra-title-line {
    min-width: 100%;
    height: 2px;
    background: black;
    border-radius: 50%;
}
#ra-grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width:470px){
    #ra-grid {
	grid-template-columns: 100%;
    }
}
.ra-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ra-i-image {
    border-radius: 50%;
    overflow: hidden;
    height: 250px;
    width: 250px;
}
.ra-i-image img {
    max-width: 100%;
    width: 250px;
    height: 250px;
    text-align: center;
    margin: auto;
}

.ra-i-btn {
    margin-top: 20px;
}


/****
   The Bullpen (LIST VIEW)
 ****/
/*center title*/
#the-bullpen-list-view-intro h1 {
    text-align: center;
    margin: auto;
}

/*** New Bullpen Styles (July 2023) ***/
#tbplv-page-content section.section-curve-bottom-white:nth-of-type(1) {
    margin-top: -136px;
    padding-bottom: 80px;
    background: #58585b;
}
#tbplv-page-content section.section-curve-bottom-white:nth-of-type(1) .scb-curve {
    background: transparent;
}
section#the-bullpen-list-view-intro {
    margin-bottom: 30px;
}
#tbplv-page-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}
@media only screen and (max-width:470px){
    #tbplv-page-content-grid {
	grid-template-columns: 100%!important;
    }
}
.tbplv-item {
    box-shadow: 0px 0px 8px 2px #efefef;
}
.tbplv-item:hover .tbplv-image-button-wrapper a {
    transform: translate(-50%,90%);
    opacity: 1;
}
.tbplv-item:hover .tbplv-image-overlay {
    background: #7ab337b0;
}
.tbplv-item-image-wrapper {
    overflow: hidden;
    clip-path: circle(99.6% at 50% 0);
    position: relative;
}
.tbplv-image-overlay {
    transition: 1s all;
    position: absolute;
    width: 100%;
    height: 100%;
}
.tbplv-image-overlay:hover {
    background: #7ab337b0;
}
.tbplv-image-button-wrapper *{
    transition: 1s all;
}
.tbplv-image-button-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.tbplv-image-button-wrapper a {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,500%);
    opacity: 0;
}
.tbplv-image-button-wrapper:hover a {
    transform: translate(-50%,90%);
    opacity: 1;
}
.tbplv-image img {
    max-width: 100%;
}

.tbplv-item-content-wrapper {
    padding: 30px;
}
.tbplv-item-content-wrapper a {
    color: #000;
}
.tbplv-item-title {
    font-size: 20px;
    text-align: center;
}
.tbplv-date {
    text-align: center;
}
.tbplv-exerpt * {
    text-align: center;
}
/*search bar*/
#bullpen-search-box {
    background: #58585b;
    /*min-width: 100vw;*/
    margin: 0;
    padding-bottom: 50px;
}
#bullpen-search-box {
    display: block;
}
#bpsb-categories-search-form form {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 20px;
}
@media only screen and (max-width:470px){
    #bpsb-categories-search-form form {
	display: grid;
    }
    button#bpsb-categories-search-submit {
	margin-top: 20px;
    }
}
#bpsb-categories-search-form #bpsb-categories-category-input {
    max-width: 230px;
    border: unset!important;
    border-bottom: 1px solid!important;
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important;
    background: unset;
}
#bpsb-categories-search-form p, #bpsb-categories-search-form select {
    color: white!important;
}
#bpsb-categories-category-input option {
    background: grey;
}
select#bpsb-categories-category-input:focus {
    outline: none;
}

/****
   Daisy Cares (List View)
 ****/
#dclv-page-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}
@media only screen and (max-width:470px){
    #dclv-page-content-grid {
	grid-template-columns: 100%!important;
    }
}
.dclv-item {
    box-shadow: 0px 0px 8px 2px #efefef;
}
.dclv-item:hover .dclv-image-button-wrapper a {
    transform: translate(-50%,90%);
    opacity: 1;
}
.dclv-item:hover .dclv-image-overlay {
    background: #7ab337b0;
}
.dclv-item-image-wrapper {
    overflow: hidden;
    clip-path: circle(84.0% at 50% 0);
    position: relative;
}
.dclv-image-overlay {
    transition: 1s all;
    position: absolute;
    width: 100%;
    height: 100%;
}
.dclv-image-overlay:hover {
    background: #7ab337b0;
}
.dclv-image-button-wrapper *{
    transition: 1s all;
}
.dclv-image-button-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.dclv-image-button-wrapper a {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,500%);
    opacity: 0;
}
.dclv-image-button-wrapper:hover a {
    transform: translate(-50%,90%);
    opacity: 1;
}
.dclv-image {
    text-align: center;
}
.dclv-image img {
    max-width: 100%;
    max-height: 300px;
}

.dclv-item-content-wrapper {
    padding: 30px;
}
.dclv-item-content-wrapper a {
    color: #000;
}
.dclv-item-title {
    font-size: 20px;
    text-align: center;
}
.dclv-date {
    text-align: center;
}
.dclv-exerpt * {
    text-align: center;
}

/****
   Daisy Cares Page View
 ****/
/*most styles for this section are borrowed fdrom the bull pen, which is why there are not many style rules here*/
div#tbpvp-g-back * {
    color: #000;
}
div#tbpvp-g-back * {
    color: #000;
}
#tbpvp-g-back span {
    font-size: 25px;
    line-height: 23px;
}
#tbpvp-g-back a {
    display: flex;
    gap: 10px;
}
/****
   Careers Page View (Single Career Page/s)
 ****/
/**main content grid**/
.cpv-grid {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 30px;
}
@media only screen and (max-width:470px){
    .cpv-grid {
	grid-template-columns: 100%;
    }
}

/**attributes**/
section#career-attributes-full {
    margin-bottom: 30px;
}
#career-attributes-full-grid {
    padding: 20px;
    border: 2px solid #999;
}
.cafg-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
}

/**map**/
#career-map {
    margin-bottom: 30px;
}
#career-map-wrapper {
    padding: 20px;
    border: 2px solid #999;
}
#career-map-wrapper img {
    max-width: 100%;
    min-width: 100%;
}

/**Apply now**/
#careers-page-apply-now {
    margin-bottom: 30px;
}
#cpan-wrapper {
    padding: 20px;
    border: 2px solid #999;
}
p#cpan-title {
    text-align: center;
    margin-top: 0;
}
form#cpan-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
input#cpan-cv {
    border: unset!important;
    max-width: 100%;
}
/*override materialize css*/
/**find me 1**/
/*
   [type="checkbox"]:not(:checked), [type="checkbox"]:checked {
   position: unset;
   opacity: unset;
   pointer-events: unset;
   }
   #cpan-btn-wrapper-outer {
   display: flex;
   justify-content: center;
   }
   #cpan-btn-wrapper {
   padding: 20px;
   display: flex;
   align-content: center;
   justify-content: center;
   align-items: center;
   background: white;
   margin-bottom: -50px;
   width: 100px;
   }
   #cpan-consent-wrapper {
   font-size: 10px;
   display: flex;
   gap: 18px;
   }
   /*

   /****
   Careers Page (List View) Archive
 ****/
/**intro**/
#clvi-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    background-position-y: 130px;
    background-repeat: no-repeat;
}
@media only screen and (max-width:470px){
    #clvi-grid {
	grid-template-columns: 100%;
	background: unset!important;
    }
    #clvi-g-right img {
	max-width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
    }
}

/**filters**/
#careers-list-view-filters{
    margin-top:30px;
    margin-bottom:30px;
}
#clvf-wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}
.clvf-item {
    border: 2px solid #000;
    border-radius: 50px;
    padding: 0px 25px;
    cursor: pointer;
}
.clvf-item p {
    padding: 20px 15px;
    line-height: 0;
    margin: -6px 0;
}

/**Carousel Global Styles For Careers List View**/
.careers-list-view-carousel {
    padding-top: 30px;
    padding-bottom: 40px;
}
.clvc-right, .clvc-left{
    cursor: pointer;
}
.clvc-right {
    float: right;
    margin-right: -40px;
    margin-top: -320px;
}
.clvc-left {
    float: left;
    margin-left: -40px;
    margin-top: 242px;
}
@media only screen and (max-width:470px){
    .clvc-right, .clvc-left{
	display: none!important;
    }
}
.clvc-item {
    display: flex;
    padding: 20px;
    border: 2px solid #999;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}
.clvc-i-attributes-item-wrapper {
    display: flex;
    gap: 20px;
}
.clvc-i-btn-wrapper {
    background: #fff;
    padding: 0 50px;
    margin-bottom: -40px;
    margin-top: 20px;
    align-self: center;
}
.clvc-i-attributes-item-wrapper img {
    min-width: 40px;
}
.clvc-i-title p {
    font-size: 24px;
}


/****
   FAQ's Page
 ****/
/**Filters**/
#faqp-wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}
.faq-filter-item {
    -webkit-text-size-adjust: 100%;
    font-size: 15px;
    border: 2px solid #000;
    border-radius: 50px;
    padding: 0px 25px;
    cursor: pointer;
    max-width: 140px;
    min-width:140px;
    width: 140px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

/**faq sections**/
.faq-section {
    margin-top: 30px;
    margin-bottom: 30px;
}

/**questions**/
.faq-question-wrapper {
    border: 1px solid #aaa;
    padding: 10px 20px;
    border-radius: 40px;
    cursor:pointer;
    z-index:9;
    position:relative;
    background:#fff;
}
.faq-question-wrapper:not(.active) {
    margin-bottom: 30px;
}

.faq-question-wrapper:not(.active)::before {
    content: "";
    position: absolute;
    right: 40px;
    top: 33px;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.faq-question-wrapper.active::before {
    content: "";
    position: absolute;
    right: 40px;
    top: 33px;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

/**answers**/
.faq-answer-wrapper {
    background: #92c83f;
    padding: 80px 20px 40px 20px;
    z-index: 1;
    margin-top: -40px;
    margin-bottom: 30px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    color: #fff;
}

/**Form**/
#faq-page-form {
    margin-bottom: 60px;
}
.form-input-2-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
@media only screen and (max-width:470px){
    .form-input-2-cols {
	grid-template-columns: 100%;
    }
}


/****
   Enterprise Development (Page View)
 ****/
/**section 1**/
#eds1-grid {
    display: grid;
    grid-template-columns: 1fr 300px;
}
#eds1g-body-1 {
    grid-row: 2/3;
    grid-column: 1/2;
}
#eds1g-logo-grid {
    grid-row: 3/4;
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 260px 260px;
    align-items: center;
    grid-gap: 30px;
    margin-top: 30px;
}
div[id^="eds1glg-item-"] img {
    max-width: 200px;
}
#eds1g-side-image {
    grid-row: 1/3;
}
#eds1g-side-image {
    grid-row: 1/4;
    grid-column: 2/3;
}
#eds1g-side-image img {
    max-width: 100%;
}
#eds1glg-item-1 img {
    max-width: 100%;
}
@media only screen and (max-width:470px){
    #eds1-grid {
	grid-template-columns:100%;
    }
    #eds1g-logo-grid{
	grid-template-columns:1fr 1fr;
	grid-gap:30px;
    }
    #eds1-grid *:not(#eds1-grid div:nth-child(2)){
	grid-column:1/2;
    }
    #eds1-grid div:nth-child(2){
	grid-row:unset!important;
    }
    #eds1-grid div:nth-child(1){
	grid-row: 1/2;
    }
    #eds1-grid div:nth-child(2){
	grid-row: 2/3;
    }
    #eds1-grid div:nth-child(3){
	grid-row: 3/4;
    }
    #eds1-grid div:nth-child(4){
	grid-row: 4/5;
    }
}
/**section 2**/
#enterprise-development-section-2 {
    margin-top: 30px;
    margin-bottom: 60px;
}
#eds2-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px;
}

#cplvi-grid-intro-wrapper {
    background-image: url(../img/img/cow_print_gradient.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
}

/******
   Connectivity Page (List View)
 ******/
/**Intro**/
#cplvi-grid-intro-wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
    background-image: url(../img/img/cow_print_gradient.jpg);
}

.plvig-wrapper {
    display: grid;
}

#cplvi-g-btn {
    margin-top: 30px;
}

.cplvi-grid-image-wrapper img {
    max-width: 100%;
}

.plvig-video {
    overflow: hidden;
    border-radius: 50px;
    max-width: 600px;
    margin: 30px auto 30px auto;
}
@media only screen and (max-width:470px){
    #cplvi-grid-intro-wrapper {
	grid-template-columns: 100%;
    }
}
/*second body text*/
div[class$="-intro-body-2"] {
    margin-top: 30px;
}

/**product section**/
section[id$="-page-page-view-products-list"] {
    padding-bottom: 60px;
    padding-top:60px;
}
/*filters*/
.product-list-view-filters-wrapper {
    
}
.product-list-view-filters-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
/*
   .plvf-item > p {
   background: #fff;
   padding: 10px 20px;
   border-radius: 25px;
   border: 1px solid #000;
   box-shadow: 2px 2px 8px 0px;
   cursor:pointer;
   }
 */
.plvf-item > div {
    padding: 10px 20px;
    line-height: 0px;
    cursor: pointer;
}

/*products list*/
.product-list-view-products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-top: 60px;
}
.product-list-view-products-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
.plvpg-item {
    display: grid;
    grid-template-columns: 195px 1fr;
    grid-gap: 20px;
}
.plvpgi-image img {
    max-width: 100%;
}
.plvpgi-infor-wrapper {
    /*display: grid;*/
}
.plvpgi-title {
    align-self: self-end;
}
.plvpgi-title h2 {
    line-height: 35px;
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.plvpgi-btn-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
}


/*fix broken button styles on slightly smaller screens*/
@media only screen and (max-width: 1530px){
    .plvpgi-btn-wrapper {
	grid-template-columns: 100%;
    }
}
/*mobile styles*/
@media only screen and (max-width:470px){
    .product-list-view-products-grid, .product-list-view-products-grid, .plvpg-item {
	grid-template-columns: 100%;
    }
}

/****
   Connectivity Page (Page View)
 *****/
#connectivity-page-page-view-intro {
    padding-bottom: 60px;
}
.cppvi-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
.cppvi-g-video-wrapper {
    margin-top: 30px;
    border-radius: 50px;
    overflow: hidden;
    max-height: 400px;
}
.cppvi-g-info-wrapper {
    display: flex;
    flex-direction: column;
}
/*style for read more button - currently commented out in the markup, so we dont need this for now...*/
/*
   .cppvi-g-intro-body {
   height: 250px;
   overflow: hidden;
   transition: height 1s;
   }
 */
.cppvi-g-intro-show-more {
    margin-top: 30px;
}
@media only screen and (max-width:470px){
    .cppvi-grid {
	grid-template-columns: 100%;
    }
}
/**(other)Our products**/
#connectivity-page-page-view-other-products {
    margin-bottom: 60px;
}

#cppvop-grid {
    display: grid;
    grid-row-gap: 30px;
}
#cppvop-grid-item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    border: 2px solid #000;
    padding: 20px;
}
.other-products-info-wrapper {
    display: grid;
    grid-row-gap: 20px;
}
.other-products-grid-item-title p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
}
.other-products-grid-item-title {
    align-self: self-end;
}
@media only screen and (max-width:470px){
    #cppvop-grid-item {
	grid-template-columns: 100%;
    }
    .other-products-grid-item-image img {
	max-width: 100%;
    }
}
/**faq**/
#connectivty-page-page-view-faqs{
    padding-bottom:60px;
}



/******
   CONTACT PAGE
 ******/

/***Grey Strip***/
#contact-form-page-grey-strip {
    padding-bottom: 30px;
}
#cfpgs-grid-outer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    justify-items: center;
}
.cfpgs-inner-item-grid {
    display: grid;
    grid-template-columns: 45px 170px;
    grid-column-gap: 20px;
}
.cfpgs-inner-item-grid img {
    max-width: 100%;
}
@media only screen and (max-width:470px){
    #cfpgs-grid-outer {
	grid-template-columns: 100%;
	justify-items:unset!important;
    }
    #cfpgs-title-wrapper {
	text-align: center;
    }
    .cfpgs-inner-item-grid {
	margin-left: calc(50% - ((45px + 170px) / 2));
    }
}

/***Find A Branch***/
#map-scroll-to {
    margin-top: -100px;
    margin-bottom: 100px;
}
section#contact-us-page-find-a-branch {
    padding-bottom: 60px;
}
/**main grid**/
#cupfab-outer-grid {
    grid-template-columns: 300px 500px;
    display: grid;
    /*justify-content: center;*/
}
@media only screen and (max-width:1366px) and (min-width:470px){
    #cupfab-outer-grid {
	grid-template-columns: 220px 450px;
	display: grid;
    }
}

/***Left side***/
/**trigger button/s**/
.cupfab-trigger-wrapper:not(:first-of-type) {
    margin-top: 20px;
}
.cupfab-trigger-wrapper a {
    display: block;
    max-width: 190px;
}

/**left col wrapper**/
.cupfab-grid-left {
    width: 300px;
}
/**left side inner location item**/
.cupfab-g-l-item {
    display: grid;
    /*grid-row-gap: 20px;*/
}
/**left side inner location item inner grid**/
.cupfab-g-l-item-inner {
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-column-gap: 20px;
    align-items: center;
}
/**make link text black**/
.cupfab-g-l-item-inner a {
    color: #000;
}

/**icon images**/
.cupfab-icon img {
    max-width: 100%;
}

/**Hide / Show Email / Phone Fields (not the same as hide / show contact details section (just the email and phone fields exclusively!))**/
.hidden-text-html {
    cursor: pointer;
}

/***right side***/
.cupfab-grid-right img {
    max-width: 100%;
}
@media only screen and (max-width:470px){
    #cupfab-outer-grid {
	grid-template-columns:100%;
    }
    .cupfab-grid-left {
	width: 100%;
    }
    .cupfab-trigger-wrapper a {
	min-width: 250px;
	max-width: 250px;
	width: 250px;
	margin:auto;
    }
    .cupfab-grid-right {
	margin-top: 40px;
    }
}

/**interactive map**/
/*fly-out details*/
.map-and-flyout-wrapper {
    position: relative;
}

#glmabs-fly-out-details {
    position: absolute;
    top: 0;
    left: 0;
}

div#glmabs-fly-out-details-inner-wrapper {
    position: relative;
}

.glmabs-fly-out-item-outer-wrapper {
    position: absolute;
    width: 350px;
    opacity: 0;
    transition: 1s all;
    background: darkgrey;
    color: white;
    padding: 10px;
    border-radius: 25px;
}

.glmabs-fly-out-item-inner {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
}
.glmabs-fly-out-text p {
    margin: 5px 10px;
}

/*map*/
#interactive-map-v4-wrapper {
    z-index: 99;
    position: relative;
    margin-top: 60px;
}
.imp-background {
    background: unset!important;
}
/*pin sizes*/
.imp-object-spot img {
    max-width: 30px;
    max-height: 30px;
}
.imp-object-icon-shadow {
    width: 30px;
    height: 30px;
    top: 20px!important;
}


/**log a service call button (under contact form)**/
div#log-service-call-contact {
    padding-top: 40px;
}

/****
   Franchise Opportuniteis (page-view)
 ****/
.fo-form-wrapper {
    margin-bottom: 60px;
}
.fo-form-inner-wrapper-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
@media only screen and (max-width:470px){
    .fo-form-inner-wrapper-2-col {
	grid-template-columns: 100%!important;
    }
}
textarea#business-product-description {
    max-height: unset;
    min-height: 120px;
    margin-left: -10px;
    min-width: calc(100% + 10px);
    padding-top: 15px;
}
.fo-form-wrapper input, .fo-form-wrapper textarea {
    margin-top: 5px!important;
}
input#vat-number {
    min-width: calc(100% + 15px);
}
.fo-form-heading {
    font-size: 20px;
    color: #58585b;
    font-family: 'Arial Rounded Bold';
    margin-bottom: 0;
}
div#errorHTML {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: red;
    border-radius: 15px;
    margin-top: 20px;
    color: white;
    font-weight: 900;
}

/****
   Completed Projects (List View)
 ****/
#completed-projects-list-view-grid-wrapper {
    margin-top: 30px;
    margin-bottom: 60px;
}
div#completed-projects-list-view-grid-inner-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
@media only screen and (max-width:470px){
    div#completed-projects-list-view-grid-inner-wrapper {
	grid-template-columns: 100%!important;
    }
}
.cp-list-view-grid-item {
    display: grid;
    grid-template-columns: 200px auto;
    gap: 20px;
}
.cplvi-image {
    border-radius: 25px;
    width: 200px;
    height: auto;
}
.cplvi-image img {
    max-width: 100%;
    border-radius:25px;
}
.cplvi-snippet {
    margin-top: -25px;
}
div#cp-paginate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
}
#cp-paginate span.page_disabled.prev {
    display: none;
}
#cp-paginate .pagination {
    display: flex;
    gap: 10px;
}
#cp-paginate .pagination * {
    color: #000;
    font-size: 20px;
}

/****
   Completed Projects (Page View)
 ****/
div#cp-so-grid {
    display: grid;
    grid-template-columns: 500px 1fr;
    grid-gap: 50px;
}
@media only screen and(max-width:470px){
    div#cp-so-grid {
	grid-template-columns: 100%!important;
    }
}
div#cp-so-subtitle * {
    font-size: 18px;
}
.cp-data-table {
    display: grid;
    grid-template-columns: 200px auto;
    grid-gap: 0;
}
.cpdt-left {
    background: grey;
    padding: 10px;
    color: white;
    border: 1px solid white;
    font-weight: 900;
}
.cpdt-right {
    padding: 10px;
    border: 1px solid grey;
    margin-left: -1px;
}
#cp-pi-title h2, #cp-so-title h2 {
    padding-top: 0;
}
div#cp-so-image {
    overflow: hidden;
    border-radius: 40px;
    height: 500px;
    width: 500px;
}
#cp-so-image img {
    max-width: 100%;
    aspect-ratio: 1/1;
}
div#cp-pi-grid {
    display: grid;
    grid-template-columns: 1fr 500px;
    grid-gap: 45px;
}
@media only screen and(max-width:470px){
    div#cp-pi-grid {
	grid-template-columns: 100%!important;
    }
}
div#cp-pi-image {
    overflow: hidden;
    border-radius: 40px;
    height: 500px;
    width: 500px;
}
#cp-pi-image img {
    max-width: 100%;
    aspect-ratio: 1/1;
}
#cp-project-info, #cp-solution-overview {
    margin-top: 30px;
    margin-bottom: 60px;
}
div#prev-next-project {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
div#prev-next-project * {
    color: #000;
    font-size: 18px;
}
div#cp-project-back {
    margin-left: 10%;
    font-size: 18px;
    cursor: pointer;
}
div#cp-project-back * {
    color: #000;
}

div#completed-projects-page-text {
    margin-bottom: 60px;
}

/********
   Common CSS (for global or common sections)
 ********/
/****
   Our Approach (common css)
 ****/
section#our-approach {
    display: none;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #58585b;
    margin-top: 60px;
    margin-bottom: 60px;
}
#our-approach-title-wrapper * {
    color: #fff!important;
}
#our-approach-items-wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}
.our-approach-item-wrapper {
    max-width: 250px;
    border: 1px solid #92c83f;
}
.oa-item-top-wrapper {
    text-align: center;
    background: #92c83f;
    padding: 20px;
}
.oa-item-title {
    margin: 0;
}
.oa-i-item-icon-wrapper {
    margin-top: 30px;
    margin-bottom: -50px; /*offset*/
}
.oa-i-item-icon-wrapper img {
    background: white;
    padding: 5px;
    border-radius: 50%;
}
.oa-item-bottom-wrapper {
    text-align: center;
    padding: 20px;
    padding-top: 50px; /*offset*/
    background:white;
}
/*#92c83f*/

/****
   Testimonails (List View)
 ****/
#testimonials-list-view-grid-inner-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 60px;
    margin-bottom: 60px;
}
@media only screen and (max-width:470px){
    #testimonials-list-view-grid-inner-wrapper {
	grid-template-columns: 100%!important;
    }
}
.testi-list-view-grid-item {
    text-align: center;
}
.testilvi-title p {
    margin: 0;
    font-size: 20px;
}
.testilvi-job p {
    margin: 0;
    font-size: 20px;
    color:grey;
}
.testi-list-view-grid-item {
    text-align: center;
    box-shadow: 0px 0px 8px 2px #efefef;
}
.testilvi-content {
    padding: 0 30px 30px 30px;
}
.testilvi-title::before {
    content: '"';
    font-size: 75px;
    color: #82c441;
    max-height: 55px;
    display: block;
}

/****
   Our Awards (list view)
 ****/
#our-awards-list-view-grid-inner-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
@media only screen and (max-width:470px){
    #our-awards-list-view-grid-inner-wrapper, .ow-list-view-grid-item {
	grid-template-columns: 100%!important;
    }
}
.ow-list-view-grid-item {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 30px;
    align-items: center;
}
.owlvi-image img {
    max-width: 100%;
}
#our-awards-list-view-grid-wrapper {
    margin-top: 60px;
    margin-bottom: 60px;
}
